'use client'

import { EditOutlined } from '@ant-design/icons'
import { Button, Skeleton } from 'antd'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useApp } from 'src/contexts/AppContext'
import { useAuth } from 'src/contexts/AuthContext'
import { BREAK_POINT, Responsive } from 'src/contexts/ResponsiveContext'
import { getDocumentAttributeValue } from 'src/helpers/helper.client'
import { useCategories } from 'src/hooks/category'
import styled from 'styled-components'
import { StyledSearch } from '../common'

const StyledCategoryBlock = styled.div<{ $showFeaturedArticles?: boolean }>`
  box-shadow: var(--theme-box-shadow);
  background-color: var(--theme-component-background-color);
  overflow-x: scroll;
  white-space: nowrap;
  padding: 0 20px;

  @media screen and (min-width: ${BREAK_POINT}px) {
    border-radius: ${props => (props.$showFeaturedArticles ? '20px 20px 0 0' : '20px')};
    overflow-x: auto;
    white-space: none;
    padding: 0;
  }

  .wrapper {
    width: 100%;
  }

  &.fixed-top {
    position: fixed;
    top: calc(var(--header-height, 65px) - 1px);
    left: 0%;
    width: 100%;
    border-radius: 0 !important;
    background: var(--theme-header-background-color) !important;
    z-index: 10;

    .wrapper {
      width: auto;
    }
  }

  .category-item {
    padding: 16px 20px;
    color: var(--theme-primary-color);
    text-transform: capitalize;
  }
`

const ArticleCategoryBlock: React.FC<{
  articleId?: string
  currentCategorySlug?: string
  showFeaturedArticles?: boolean
  isFixed?: boolean
}> = ({ articleId, currentCategorySlug, showFeaturedArticles, isFixed }) => {
  const intl = useIntl()
  const router = useRouter()
  const { backDomain } = useApp()
  const { currentMemberRole } = useAuth()
  const { loadingCategories, categories } = useCategories('article', { parentOnly: true })

  const [tabRef, setTabRef] = useState<HTMLDivElement | null>()
  const [fixedTop, setFixedTop] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      if (tabRef && isFixed) {
        const headerHeight = parseInt(getDocumentAttributeValue('--header-height'))
        const { top } = tabRef.getBoundingClientRect()
        setFixedTop(top < headerHeight)
      }
    }
    document.addEventListener('scroll', onScroll, { passive: true })

    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [tabRef, isFixed])

  return (
    <div>
      <Responsive.Default>
        <div className="mx-4 my-3">
          <StyledSearch
            placeholder={intl.formatMessage({ id: 'article.search.placeholder' })}
            onSearch={value => {
              window.sendEvent?.('search', { search_term: value })
              router.push(`/search?q=${value}`)
            }}
          />
        </div>
      </Responsive.Default>

      <div ref={setTabRef}></div>
      <StyledCategoryBlock
        className={`d-lg-flex justify-content-center align-items-center ${fixedTop ? 'fixed-top' : ''}`}
        $showFeaturedArticles={showFeaturedArticles}
      >
        <Skeleton className="px-3 py-2" loading={loadingCategories} paragraph={false}>
          <div className="wrapper">
            {!['user', 'anonymous'].includes(currentMemberRole) && articleId && (
              <Button
                type="primary"
                shape="round"
                icon={<EditOutlined />}
                href={`https://${backDomain}/post/${articleId}`}
                target="_blank"
                rel="noreferrer"
              >
                編輯文章
              </Button>
            )}

            <Link href="/articles" className={`d-inline-block category-item ${!currentCategorySlug ? 'active' : ''}`}>
              <FormattedMessage id="article.category.allArticles" />
            </Link>

            {categories.map(category => {
              const categorySlug = category.slug || category.id
              const isActive = currentCategorySlug === categorySlug

              return (
                <Link
                  key={category.id}
                  href={`/articles/${categorySlug}`}
                  className={`d-inline-block category-item ${isActive ? 'active' : ''}`}
                >
                  {category.name}
                </Link>
              )
            })}
          </div>
        </Skeleton>
      </StyledCategoryBlock>
    </div>
  )
}

export default ArticleCategoryBlock
