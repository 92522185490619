'use client'

import { captureException } from '@sentry/nextjs'
import { GetStaticPropsContext, GetStaticPropsResult, Redirect } from 'next'
import { createApolloClient } from 'src/helpers/apollo.client'
import { DEFAULT_LOCAL } from 'src/libs/languageLib'
import { ApplicationProps } from 'src/types/app'
import { PageProps } from 'src/types/shared'
import { fetchAppByAppId } from 'src/utils/app'
import { getAppIdInContextParams } from 'src/utils/appId'
import { GetPropsFunc } from '.'

export const withStaticHostProps = <
  T extends { props?: { [key: string]: any }; revalidate?: number | boolean; redirect?: Redirect },
>(
  getStaticPropsFunc?: GetPropsFunc<GetStaticPropsContext, T>,
  options?: { isAdminSecretEnabled?: boolean },
) => {
  const WithHost: (context: GetStaticPropsContext) => Promise<GetStaticPropsResult<PageProps>> = async context => {
    const previewData = context.previewData as any
    const isPreview = Boolean(context.preview)
    const isPreviewPurchased = Boolean(previewData?.isPreviewPurchased)

    const adminSecret = options?.isAdminSecretEnabled || isPreview ? process.env.HASURA_ADMIN_SECRET : undefined
    const appId = getAppIdInContextParams(context.params)

    let app: ApplicationProps | null = null
    try {
      app = await fetchAppByAppId(appId)
    } catch (error) {
      console.error(error)
      captureException(error)
    }

    const apolloClient = createApolloClient({ appId, adminSecret })
    const staticPropsPromise = getStaticPropsFunc?.(context, { apolloClient, appId, app })

    let staticProps: { [key: string]: any } | undefined = {}
    let revalidate: number | boolean = false
    let redirect: Redirect | null = null
    try {
      const staticPropsResult = await staticPropsPromise
      staticProps = staticPropsResult?.props
      revalidate = staticPropsResult?.revalidate ?? false
      redirect = staticPropsResult?.redirect ?? null
    } catch (error) {
      console.error(error)
      captureException(error)
    }

    const initialApolloState = apolloClient?.cache.extract() || null
    const defaultLocale = app?.defaultLocale ?? DEFAULT_LOCAL
    const layoutType = app?.layoutType ?? 'default'
    return {
      props: {
        isPreview,
        isPreviewPurchased,
        app,
        appId,
        layoutType,
        defaultLocale,
        initialApolloState,
        ...staticProps,
      },
      revalidate,
      redirect,
    }
  }
  return WithHost
}
