'use client'

import clsx from 'clsx'
import Image from 'next/legacy/image'
import Link from 'next/link'
import React from 'react'
import { BREAK_POINT_MD } from 'src/contexts/ResponsiveContext'
import { ArticleBasicProps } from 'src/types/article'
import styled from 'styled-components'

const StyledBlockDiv = styled.div`
  box-shadow: var(--theme-box-shadow);

  &.rounded {
    overflow: hidden;
    @media screen and (min-width: ${BREAK_POINT_MD}px) {
      border-radius: 0 0 20px 20px;
    }
  }

  .featured-block {
    width: 100%;
    .title-block {
      padding: 12px 36px;
      min-height: 80px;
      .title {
        font-size: 20px;
      }
    }
  }

  .other-block {
    width: 100%;
    @media screen and (min-width: ${BREAK_POINT_MD}px) {
      width: 50%;
    }
    .title-block {
      padding: 12px 24px;
      min-height: 80px;
      .title {
        font-size: 16px;
      }
    }
  }

  .title-block {
    position: absolute;
    width: 100%;
    bottom: 0;

    background-color: rgba(0, 0, 0);
    opacity: 0.8;
    .title {
      color: white;
    }
  }
`

const FeaturedArticleBlock: React.FC<React.PropsWithChildren<{ rounded?: boolean; articles: ArticleBasicProps[] }>> = ({
  rounded,
  articles,
}) => {
  const [featuredArticle, ...otherArticles] = articles
  const featureArticlePath = `/${featuredArticle.categories?.[0] ? featuredArticle.categories[0].slug : 'article'}/${
    featuredArticle.slug || featuredArticle.id
  }`

  return (
    <StyledBlockDiv
      className={clsx('d-flex flex-column flex-md-row', {
        rounded: rounded,
      })}
    >
      {featuredArticle.featuredImageUrl && (
        <Link href={featureArticlePath} className="featured-block d-flex position-relative">
          <Image
            src={featuredArticle.featuredImageUrl}
            alt={featuredArticle.title}
            width={1366}
            height={768}
            objectFit="cover"
            unoptimized={
              featuredArticle.featuredImageUrl.endsWith('.svg') || featuredArticle.featuredImageUrl.endsWith('.gif')
            }
          />
          <div className="title-block d-flex align-items-center">
            <h2 className="title">{featuredArticle.title}</h2>
          </div>
        </Link>
      )}
      {otherArticles.length > 0 && (
        <div className="other-block">
          {otherArticles.map((article, index) => {
            return article.featuredImageUrl ? (
              <Link
                key={index}
                href={`/article/${article.slug || article.id}`}
                className={clsx('position-relative', {
                  'd-flex': index === 0,
                  'd-none d-md-flex': index > 0,
                })}
              >
                <Image
                  src={article.featuredImageUrl}
                  alt={article.title}
                  width={1366}
                  height={768}
                  objectFit="cover"
                  unoptimized={article.featuredImageUrl.endsWith('.svg') || article.featuredImageUrl.endsWith('.gif')}
                />
                <div className="title-block d-flex align-items-center">
                  <h2 className="title">{article.title}</h2>
                </div>
              </Link>
            ) : null
          })}
        </div>
      )}
    </StyledBlockDiv>
  )
}

export default FeaturedArticleBlock
