'use client'

import React, { useContext, useEffect, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { ResponsiveContext } from 'src/contexts/ResponsiveContext'
import LoadingSection from './loading/LoadingSection'

const InfiniteScrollElement: React.FC<
  React.PropsWithChildren<{
    itemLength: number
    loading?: boolean
    hasMore?: boolean
    rootMargin?: string
    loadMore?: () => void
    renderNoResult?: () => React.ReactNode
  }>
> = ({ itemLength, loading, hasMore, rootMargin, loadMore, renderNoResult, children }) => {
  const { isMobile } = useContext(ResponsiveContext)
  const [blockRootMargin, setBlockRootMargin] = useState('0px')
  useEffect(() => {
    setBlockRootMargin(rootMargin || isMobile ? '0px' : '-500px 0px 500px 0px')
  }, [isMobile, rootMargin])

  if (loading && itemLength > 0) {
    return <LoadingSection height={400} />
  }
  if (!loading && itemLength === 0) {
    return renderNoResult ? (
      <>{renderNoResult()}</>
    ) : (
      <div>
        <div className="text-center py-5">無結果</div>
      </div>
    )
  }

  return (
    <>
      <div>{children}</div>
      {!loading && hasMore && (
        <InView
          rootMargin={blockRootMargin}
          onChange={inView => {
            if (inView && hasMore) {
              loadMore?.()
            }
          }}
        >
          <LoadingSection height={400} />
        </InView>
      )}
    </>
  )
}

export default InfiniteScrollElement
