'use client'

import { Skeleton, Typography } from 'antd'
import { NextPage } from 'next'
import { useParams } from 'next/navigation'
import { useRouter as usePageRouter } from 'next/router'
import { useContext, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import ArticleCategoryBlock from 'src/components/article/ArticleCategoryBlock'
import ArticleRow from 'src/components/article/ArticleRow'
import FeaturedArticleBlock from 'src/components/article/FeaturedArticleBlock'
import InfiniteScrollElement from 'src/components/common/InfiniteScrollElement'
import StructuredData from 'src/components/common/StructuredData'
import { withStaticHostProps } from 'src/components/hoc/withStaticHostProps'
import DefaultLayout from 'src/components/layouts/DefaultLayout'
import { useApp } from 'src/contexts/AppContext'
import { useAuth } from 'src/contexts/AuthContext'
import PreviewContext from 'src/contexts/PreviewContext'
import { BREAK_POINT } from 'src/contexts/ResponsiveContext'
import { useArticles } from 'src/hooks/article.client'
import { useCategories } from 'src/hooks/category'
import styled from 'styled-components'

const StyledArticleSectionDiv = styled.div`
  box-shadow: var(--theme-box-shadow);
  background-color: var(--theme-component-background-color);
  border-radius: 10px;
`
export const StyledArticleRowWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  .cover-image {
    border-radius: 10px;
  }
  .text-box {
    align-self: stretch !important;
    justify-content: space-between;
  }
  .continue-reading-button {
    margin-top: 8px;
    @media screen and (min-width: ${BREAK_POINT}px) {
      position: absolute;
      right: 16px;
      bottom: 0;
      margin-top: 0;
    }
  }
`
const StyledArticleListSection = styled.div`
  .section-title {
    font-size: 28px;
    text-transform: capitalize;
  }
`

const ArticleListPage: NextPage = () => {
  const pageRouter = usePageRouter()
  const params = useParams()
  const { formatMessage } = useIntl()
  const { frontDomain } = useApp()
  const { isAuthenticated, currentMember } = useAuth()
  const { categories } = useCategories('article')

  const categorySlug = (params?.slugs?.[0] ?? pageRouter.query.slugs?.[0] ?? pageRouter.query.category) as
    | string
    | undefined
  const { loadingArticles, articles, fetchMoreArticles, isNoMoreArticles } = useArticles({
    orderBy: 'latest',
    categorySlug,
  })

  const pageTitle = useMemo(
    () =>
      categorySlug
        ? categories.find(category => (category.slug || category.id) === categorySlug)?.name
        : formatMessage({ id: 'article.category.allArticles' }),
    [categories, categorySlug, formatMessage],
  )

  const splicedArticles = useMemo(() => {
    return articles.filter(({ featuredImageUrl }) => Boolean(featuredImageUrl)).slice(0, 3)
  }, [articles])

  const { isPreviewBarVisible, setAdminPathname } = useContext(PreviewContext)
  useEffect(() => {
    if (isPreviewBarVisible) {
      setAdminPathname?.('/posts')
    }
  }, [isPreviewBarVisible, setAdminPathname])

  const isProductVisible = (data: any, isAuthenticated: boolean, currentMemberId: string) => {
    if (isAuthenticated && currentMemberId) {
      return data.groupMembers.includes(currentMemberId)
    } else {
      return data.groupMembers.length === 0 || !data.groupMembers
    }
  }

  return (
    <DefaultLayout headerFixed>
      <StructuredData
        data={{
          '@context': 'https://schema.org',
          '@type': 'ItemList',
          name: pageTitle,
          numberOfItems: articles.length,
          itemListOrder: 'Descending',
          itemListElement: articles.map(article => ({
            '@type': 'Article',
            headline: article.title,
            image: article.featuredImageUrl ? [article.featuredImageUrl] : [],
            url: article.path,
            datePublished: article.onlineAt?.toISOString(),
            dateModified: article.updatedAt?.toISOString(),
            author: article.author
              ? [
                  {
                    '@type': 'Person',
                    name: article.author.displayName || '',
                    url: `https://${frontDomain}/author/${article.author.displayName || ''}`,
                  },
                ]
              : undefined,
          })),
        }}
      />

      <div className="container px-0 px-sm-3">
        <div className="mt-3 mt-sm-5">
          <ArticleCategoryBlock
            isFixed
            currentCategorySlug={categorySlug}
            showFeaturedArticles={splicedArticles.length === 3}
          />

          <Skeleton className="p-4" loading={loadingArticles}>
            {splicedArticles.length === 3 && <FeaturedArticleBlock rounded articles={splicedArticles} />}
          </Skeleton>
        </div>
      </div>

      <div className="container ">
        <div className="row mt-4">
          <div className="col-lg-9">
            <StyledArticleListSection>
              <Typography.Title level={2} className="mb-3 section-title">
                {pageTitle}
              </Typography.Title>

              <StyledArticleSectionDiv className="py-4 px-4 mb-4">
                <InfiniteScrollElement
                  itemLength={articles.length}
                  loading={loadingArticles}
                  hasMore={!isNoMoreArticles}
                  loadMore={() => fetchMoreArticles?.()}
                >
                  {articles.map(article => (
                    <StyledArticleRowWrapper key={article.id} className="mb-4">
                      <ArticleRow
                        article={article}
                        noCategory
                        showAbstract
                        showContinueReadingButton
                        datetimeFormat="YYYY-MM-DD"
                        // TODO: uncomment this when Group limitation features is fully released
                        // isVisible={isProductVisible(article, isAuthenticated, currentMember.id)}
                      />
                    </StyledArticleRowWrapper>
                  ))}
                </InfiniteScrollElement>
              </StyledArticleSectionDiv>
            </StyledArticleListSection>
          </div>
        </div>
      </div>

      {/* <CourseRecommendSection title={`${appName} 推薦你`} subtitle="根據你的興趣，推薦專屬於你的課程" /> */}
    </DefaultLayout>
  )
}

export const getStaticProps = withStaticHostProps()
export default ArticleListPage
