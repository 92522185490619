import { gql, useQuery } from '@apollo/client'
import types from '@havppen/gql/types'
import { CategoryProps, CategoryType } from '@havppen/types/src/category'
import { useMemo, useState } from 'react'
import { useApp } from 'src/contexts/AppContext'

export const mapCategoryQueryOptionsToGqlVariables = (
  options: {
    categorySlug: string
    type: CategoryType
    limit?: number
  },
  params: {
    appId: string
  },
) => {
  const variables: types.GET_CATEGORYVariables = {
    categorySlug: options.categorySlug,
    type: options.type,
    appId: params.appId,
    limit: options.limit,
  }
  return variables
}
export const useCategory = (categorySlug: string, type: CategoryType) => {
  const { id: appId } = useApp()
  const { loading, error, data, refetch } = useQuery<types.GET_CATEGORY, types.GET_CATEGORYVariables>(GET_CATEGORY, {
    skip: !categorySlug || !type,
    variables: mapCategoryQueryOptionsToGqlVariables({ categorySlug, type, limit: 1 }, { appId }),
  })

  const category: CategoryProps | null = useMemo(() => {
    if (!data || !data.category) return null

    const category = data.category[0]
    const parent_category = category.parent_category
    return {
      id: category.id,
      name: category.name,
      slug: category.slug,
      metadata: category.metadata,
      parentCategory: parent_category
        ? {
            id: parent_category.id,
            name: parent_category.name,
            slug: parent_category.slug,
            metadata: parent_category.metadata,
            parentCategory: parent_category.parent_category
              ? {
                  id: parent_category.parent_category.id,
                  name: parent_category.parent_category.name,
                  slug: parent_category.parent_category.slug,
                  metadata: parent_category.parent_category.metadata,
                }
              : null,
          }
        : null,
    }
  }, [data])

  return {
    loadingCategory: loading,
    errorCategory: error,
    category,
    refetchCategory: refetch,
  }
}

type CategoryQueryOptions = {
  parentOnly?: boolean
  categorySlugs?: string[]
  parentCategorySlug?: string | null
}
export const mapCategoriesQueryOptionsToGqlVariables = (
  type: CategoryType,
  options?: CategoryQueryOptions & {
    appId?: string
  },
) => {
  const variables: types.GET_CATEGORIESVariables = {
    condition: {
      type: { _eq: type },
      parent_id: options?.parentOnly ? { _is_null: true } : undefined,
      parent_category: options?.parentCategorySlug ? { slug: { _eq: options.parentCategorySlug } } : undefined,
      app_id: options?.appId ? { _eq: options.appId } : undefined,
    },
  }

  return variables
}

export const useCategories = (type: CategoryType, options?: CategoryQueryOptions) => {
  const { id: appId } = useApp()
  const { loading, error, data, refetch } = useQuery<types.GET_CATEGORIES, types.GET_CATEGORIESVariables>(
    GET_CATEGORIES,
    {
      skip: typeof options?.parentCategorySlug === 'string' && !options?.parentCategorySlug,
      variables: mapCategoriesQueryOptionsToGqlVariables(type, { ...options, appId }),
    },
  )

  const [isFetchingCategories, setIsFetchingCategories] = useState(false)
  const refetchCategories = async () => {
    setIsFetchingCategories(true)
    await refetch()
    await new Promise(resolve => setTimeout(resolve, 200))
    setIsFetchingCategories(false)
  }

  const categories: CategoryProps[] = useMemo(
    () =>
      (data?.category || []).map(category => ({
        ...category,
        subcategories: category.subcategories?.map(subcategory => ({
          ...subcategory,
          subcategories: subcategory.subcategories?.map(subcategory => ({
            ...subcategory,
          })),
        })),
      })),
    [data],
  )
  return {
    loadingCategories: loading,
    errorCategories: error,
    categories,
    isFetchingCategories,
    refetchCategories,
  }
}

export const GET_CATEGORY = gql`
  query GET_CATEGORY($categorySlug: String!, $type: String!, $appId: String!, $limit: Int) {
    category(where: { slug: { _eq: $categorySlug }, type: { _eq: $type }, app_id: { _eq: $appId } }, limit: $limit) {
      id
      name
      slug
      metadata
      updated_at
      parent_category {
        id
        name
        slug
        metadata
        updated_at
        parent_category {
          id
          name
          slug
          metadata
          updated_at
          position
        }
      }
    }
  }
`

export const GET_CATEGORIES = gql`
  query GET_CATEGORIES($condition: category_bool_exp) {
    category(where: $condition, order_by: { position: asc }) {
      id
      name
      slug
      updated_at
      metadata
      subcategories(order_by: { position: asc }) {
        id
        name
        slug
        updated_at
        metadata
        subcategories(order_by: { position: asc }) {
          id
          name
          slug
          updated_at
          metadata
          position
        }
      }
    }
  }
`
export const GET_CATEGORY_SLUGS = gql`
  query GET_CATEGORY_SLUGS {
    category(where: { deleted_at: { _is_null: true } }) {
      slug
    }
  }
`
